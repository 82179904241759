import { createContext, FC, forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import LoadingComponent from "../components/LoadingComponent"
import { loadFishData } from "../Hooks"
import { FishContext, FishData, FishLocationData, ForwardReferenceLoadState, LoadState, MonthOrAll } from "../types"

const Context = createContext<FishContext | null>(null)
export const useFishContext = () => {
  const context = useContext(Context)
  if (context === null) {
    throw new Error("useAppContext must be used within a provider")
  }
  return context
}

let hasRequest = false

const FishDataContext: FC<{ apiKey: string, map: google.maps.Map | null, setErrors: (errors: string[]) => void, setTotalRequests: (req: number) => void }> = ({ apiKey, map, children, setErrors, setTotalRequests }) => {
  const [fishData, setFishData] = useState<readonly FishData[] | null>(null)
  const loadingRef = useRef<ForwardReferenceLoadState>(null)

  if (fishData === null && map !== null && !hasRequest) {
    loadFishData(map, apiKey, setFishData, state => loadingRef?.current?.setLoadState?.(state), setErrors, setTotalRequests)
    hasRequest = true
  }

  const isFirstTime = useMemo(() => {
    const isFirstTime = localStorage.getItem("has-visited-before") !== "true"
    localStorage.setItem("has-visited-before", "true")
    return isFirstTime
  }, [])

  const [selectedMonth, setSelectedMonth] = useState<MonthOrAll>(() => "All")
  const [hoveredFish, setHoveredFish] = useState<readonly FishLocationData[]>([])
  const [selectedFish, setSelectedFish] = useState<readonly FishData[]>([])
  const [searchedFish, setSearchedFish] = useState<readonly FishData[] | null>(null)
  const [filteredFishermen, setFilteredFishermen] = useState<readonly string[] | null>(null)
  const [selectedLocationData, setSelectedLocationData] = useState<readonly FishLocationData[]>([])

  const previousSelectedFish = useRef(selectedFish)
  useEffect(() => {
    if (selectedFish.length === 0 && previousSelectedFish.current.length !== 0) {
      setSelectedMonth("All")
    }
    previousSelectedFish.current = selectedFish
  }, [selectedFish])


  const currentFishData = useMemo(() =>
    (fishData ?? [])
      .filter(fish => searchedFish !== null ? searchedFish.includes(fish) : (selectedFish.length === 0 || selectedFish.includes(fish)))
      .map(fish => ({
        originalFish: fish,
        points: fish.locationData
          .filter(f => selectedMonth === "All" || f.months.includes(selectedMonth))
          .filter(f => filteredFishermen === null || filteredFishermen.includes(f.fisherName))
      }))
      .filter(fish => fish.points.length !== 0),
    [fishData, selectedMonth, selectedFish, searchedFish, filteredFishermen])

  if (fishData === null) {
    return <MapLoading ref={loadingRef} />
  }

  return (
    <Context.Provider value={{
      allFishData: fishData,
      isFirstTime,
      currentFishData,
      searchedFish, setSearchedFish,
      filteredFishermen, setFilteredFishermen,
      selectedMonth, setSelectedMonth,
      hoveredFish, setHoveredFish,
      selectedFish, setSelectedFish,
      selectedLocationData, setSelectedLocationData,
      get map() {
        if (!map) {
          throw new Error("getMap was called too early");

        }
        return map
      }
    }}>
      {children}
    </Context.Provider>
  )
}

const MapLoading = forwardRef<ForwardReferenceLoadState>((_, ref) => {
  const [state, setLoadState] = useState<LoadState | null>(null)
  useImperativeHandle(ref, () => ({ setLoadState }))

  let text = ""
  if (state !== null) {
    if (state.amount !== undefined && state.total !== undefined) {
      text += `${state.message} (${state.amount}/${state.total})`
    } else {
      text = state.message
    }
  }

  return <LoadingComponent title="Loading Fish Data..." subtitle={text} />
})

export default FishDataContext