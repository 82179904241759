import { useState } from "react"
import { useFishContext } from "../context/FishDataContext"
import { IconCompass, IconLoadingSpin } from "../Icons"

const MoveToLocationButton = () => {
  const { map } = useFishContext()
  const [isLoading, setIsLoading] = useState(false)
  const location = navigator.geolocation
  const onClick = () => {
    setIsLoading(true)
    location.getCurrentPosition(
      position => {
        map.panTo(new google.maps.LatLng(position.coords.latitude, position.coords.longitude))
        setIsLoading(false)
      },
      error => {
        console.warn(`Unable to get position. '${error.message}' (${error.code})`)
        setIsLoading(false)
      }
    )
  }
  if (!location) {
    return null
  }
  const Icon = isLoading ? IconLoadingSpin : IconCompass
  return (
    <button onClick={onClick} className="absolute right-5 top-5 h-8 w-8 bg-blue-800 flex justify-center items-center rounded-full">
      <Icon className="text-blue-200 w-6 h-6" />
    </button>
  )
}

export default MoveToLocationButton