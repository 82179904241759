import { useFishContext } from "../context/FishDataContext"
import { MonthOrAll, months, monthsAndAll } from "./../types"

const MonthSelection = () => {
  return (
    <>
      {/* <MonthDropdownBox /> */}
      <MonthRangeScaler />
    </>
  )
}

// eslint-disable-next-line
const MonthDropdownBox = () => {
  const { selectedMonth, setSelectedMonth } = useFishContext()
  return (
    <select className="absolute left-3 top-7" value={selectedMonth} onChange={e => setSelectedMonth(e.currentTarget.value as MonthOrAll)}>
      <option value="All">All</option>
      {months.map(m => <option key={m} value={m}>{m}</option>)}
    </select>
  )
}


const MonthTick = ({ i }: { i: number }) =>
  <div className="absolute top-0 transform h-1 bg-blue-400" style={{ width: '2px', left: `calc(100% * ${i} / 12)` }} />

const MonthLabel = ({ i, text }: { i: number, text: string }) =>
  <div className="absolute top-0 transform -translate-x-1/2 text-xs" style={{ left: `calc(100% * ${i} / 12)` }}>{text}</div>


const MonthRangeScaler = () => {
  const { selectedMonth, setSelectedMonth } = useFishContext()
  return (
    <div className="absolute max-sm:left-3 sm:left-64 right-3 bottom-7 h-14 bg-white rounded-md px-8 pt-1 flex flex-col items-center">
      <div className="text-gray-500 text-xs mx-auto">Selected: {selectedMonth}</div>
      <input
        className="slider"
        style={{ width: "calc(100% + 12px)" }}
        type="range" min={0}
        max={monthsAndAll.length - 1} step={1}
        value={monthsAndAll.indexOf(selectedMonth)}
        onChange={e => {
          window.navigator.vibrate(10)
          setSelectedMonth(monthsAndAll[e.target.valueAsNumber])
        }} />
      <div className="relative w-full mr-px">
        {monthsAndAll.map((_, i) => <MonthTick key={i} i={i} />)}
        {/* Shows up when more than 1024px */}
        <div className="max-lg:hidden">
          {monthsAndAll.map((v, i) => <MonthLabel key={i} i={i} text={v} />)}
        </div>

        {/* Shows up when more than 768px, but less than 1024px */}
        <div className="lg:hidden max-md:hidden">
          {monthsAndAll.map((v, i) => i % 2 === 1 ? null : <MonthLabel key={i} i={i} text={v} />)}
        </div>

        {/* Shows up when more than 640px, but less than 768px
        <div className="md:hidden max-sm:hidden">
          {monthsAndAll.map((v, i) => i % 2 === 1 ? null : <MonthLabel key={i} i={i} text={v} />)}
        </div> */}

        {/* Shows up when less than 768px */}
        <div className="md:hidden">
          {monthsAndAll.map((v, i) => i % 3 !== 0 ? null : <MonthLabel key={i} i={i} text={v} />)}
        </div>
      </div>
    </div>
  )
}

export default MonthSelection