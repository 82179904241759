import { GoogleSpreadsheet } from "google-spreadsheet";
import { FishData, LoadState, Month, months, StorageItem, UnFormattedFishData } from "./types";

const placeNameCacheKey_v1 = "place-name-cache-v1"

export const loadFishData = async (map: google.maps.Map, apiKey: string, setData: (value: FishData[]) => void, setLoadingState: (state: LoadState) => void, setErrors: (errors: string[]) => void, setTotalRequests: (req: number) => void) => {
  const fishMap = new Map<string, UnFormattedFishData>()

  const errors: string[] = []
  const error = (error: string) => {
    console.warn(error)
    errors.push(error)
  }

  setLoadingState({ message: "Loading Google Doc" })
  const doc = new GoogleSpreadsheet('1-l773ZpFtTF97IagX47GkV2WEwB6bIohccQyaJyUTLk');
  doc.useApiKey(apiKey);
  await doc.loadInfo()
  const sheet = doc.sheetsByIndex[0]

  setLoadingState({ message: "Loading Rows" })
  const rows = await sheet.getRows()

  const finishRow = (index: number) => setLoadingState({ message: "Parsing rows", amount: index + 1, total: rows.length })
  finishRow(-1)
  rows.forEach((row, i) => {
    if (row['_rawData'].length === 0) {
      finishRow(i)
      return
    }
    const rowGetData = row as Record<string, string>
    const fisherman = rowGetData['Fisherman']
    const locations = rowGetData['Where']?.split("/")?.map(s => s.trim()) ?? []
    const fishMonths = rowGetData['Months']?.split(/[\s,]+/)?.filter(s => s.length !== 0)?.map(s => s.trim()) ?? []
    const fishNames = rowGetData['FishName']?.split(',')?.filter(s => s.length !== 0)?.map(s => s.trim()) ?? []
    const fishNotes = rowGetData['Notes']

    const missingData = []
    if (fisherman === "" || fisherman === undefined) missingData.push('Fisherman')
    if (locations.length === 0) missingData.push('Where')
    if (fishMonths.length === 0) missingData.push('Months')
    if (fishNames.length === 0) missingData.push('FishName')
    if (missingData.length !== 0) {
      error(`Row ${row.rowIndex} is missing fields: ${missingData}.`)
      finishRow(i)
      return
    }

    const allYear = fishMonths.join(" ").toLowerCase().startsWith("all year")
    const foundMonths = allYear ? months : fishMonths.map(testMonth => {
      if (testMonth.endsWith(".")) testMonth = testMonth.substring(0, testMonth.length - 1)
      const found = months.filter(month => month.toLowerCase().startsWith(testMonth.toLowerCase()))
      if (found.length === 0) {
        error(`Row ${row.rowIndex} month '${testMonth}' did not have a match.`)
        return null
      }
      if (found.length !== 1) {
        error(`Row ${row.rowIndex} month '${testMonth}' matched multiple times ${found}.`)
        return null
      }
      return found[0]
    }).filter(m => m !== null) as Month[]
    fishNames.forEach(fishName => {
      let f = fishMap.get(fishName.toLowerCase())
      if (!f) {
        fishMap.set(fishName.toLowerCase(), f = {
          fishName,
          locationData: []
        })
      }

      f.locationData.push({
        fisherName: fisherman,
        databaseRow: row.rowIndex,
        months: foundMonths,
        locationStrings: locations,
        locations,
        notes: fishNotes,
      })
    })
    finishRow(i)
  })

  setLoadingState({ message: "Loading Storage Data" })

  const placenameSet = new Set(Array.from(fishMap.values()).flatMap(f => f.locationData).flatMap(f => f.locations))
  const storageStr = localStorage.getItem(placeNameCacheKey_v1)
  const storage = storageStr !== null ? JSON.parse(storageStr) as StorageItem[] : []

  const service = new google.maps.places.PlacesService(document.createElement('div'))

  const placesMap = new Map<string, StorageItem>()
  const requestsToMake: string[] = []

  placenameSet.forEach(s => {
    const item = storage.find(storage => storage.placeName.toLowerCase() === s.toLowerCase())
    if (item !== undefined) {
      placesMap.set(s.toLowerCase(), item)
      return
    }
    requestsToMake.push(s)
  })

  setTotalRequests(placenameSet.size)

  const startingTries = Math.round(requestsToMake.length / 2)

  let requestsDone = 0
  const finishRequest = (timesLeft: number) => setLoadingState({ message: `Making Location Queries [Try: ${startingTries - timesLeft + 1}/${startingTries}]`, amount: requestsDone++, total: requestsToMake.length })
  finishRequest(startingTries)
  const makeRequestUntil0 = (query: string, resolve: (value: StorageItem | null) => void, reject: (reason?: any) => void, timesLeft = startingTries) => {
    service.textSearch({
      query,
      type: "regions"
    }, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results !== null) {
        const locations: StorageItem['locations'] = []
        results.forEach(r => {
          const viewport = r.geometry?.viewport
          if (!viewport) {
            return
          }
          const distance = haversineDistance(viewport.getNorthEast(), viewport.getSouthWest())
          const numRings = Math.min(Math.max(Math.floor(distance / 25000), 1), 5)

          const center = viewport.getCenter()

          const resultLocations: google.maps.LatLng[] = []
          resultLocations.push(center)
          const projection = map.getProjection()
          if (numRings !== 1 && projection !== undefined) {
            const centerPoint = projection.fromLatLngToPoint(center)
            if (centerPoint !== null) {

              for (let i = 1; i < numRings; i++) {
                const numPoints = i * 2
                const r = i * 0.01

                for (let p = 0; p < numPoints; p++) {
                  const deg = (p / numPoints) * 2 * Math.PI

                  const latLng = projection.fromPointToLatLng(new google.maps.Point(
                    centerPoint.x + r * Math.cos(deg),
                    centerPoint.y + r * Math.sin(deg)
                  ))

                  if (latLng !== null) {
                    resultLocations.push(latLng)
                  }
                }
              }
            }
          }

          resultLocations.forEach(r => locations.push({
            lat: r.lat(),
            lng: r.lng(),
            weight: 1 / resultLocations.length
          }))

        })
        finishRequest(timesLeft)
        resolve({
          placeName: query,
          locations: locations
        })
      } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        error(`The placename '${query}' gave no places.`)
        finishRequest(timesLeft)
        resolve(null)
      } else {
        if (timesLeft !== 0) {
          setTimeout(() => {
            makeRequestUntil0(query, resolve, reject, timesLeft - 1)
          }, 1000)
        } else {
          finishRequest(timesLeft)
          error(`Maximum Requests reached for location ${query}`)
          resolve(null)
        }
      }
    })
  }

  const requestedPlacesPromise = requestsToMake.map(r => new Promise<StorageItem | null>((resolve, reject) => makeRequestUntil0(r, resolve, reject)))
  const requestedPlaces = await Promise.all(requestedPlacesPromise)
  requestedPlaces.forEach(place => place !== null && placesMap.set(place.placeName.toLowerCase(), place))


  localStorage.setItem(placeNameCacheKey_v1, JSON.stringify(Array.from(placesMap.values())))

  setLoadingState({ message: "Parsing Fish Data" })


  const f = Array.from(fishMap.values()).map(fish => {
    const fishData: FishData = {
      fishName: fish.fishName,
      locationData: fish.locationData.map(d => ({
        ...d,
        locations: d.locations.flatMap(l => {
          const locations = placesMap.get(l.toLowerCase())?.locations
          if (locations) {
            return locations.map(({ lat, lng, weight }) => ({
              location: new google.maps.LatLng(lat, lng),
              weight
            }))
          }
          return undefined
        })
          .filter(l => l !== undefined) as google.maps.visualization.WeightedLocation[]
      }))
    }
    return fishData
  })

  setErrors(errors)
  setData(f)
}

function rad(x: number) {
  return x * Math.PI / 180;
};

export function haversineDistance(p1: google.maps.LatLng, p2: google.maps.LatLng) {
  var R = 6378137; // Earth’s mean radius in meter
  var dLat = rad(p2.lat() - p1.lat());
  var dLong = rad(p2.lng() - p1.lng());
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d; // returns the distance in meter
};