import { useLoadScript } from "@react-google-maps/api";
import { useMemo, useRef, useState } from "react";
import FishErrorLogModal from "./components/FishErrorLogModal";
import FishInfoModal from "./components/FishInfoModal";
import FishMap from "./components/FishMap";
import FishSelectionInputField from "./components/FishSelectionInputField";
import { FishTooltipHover } from "./components/FishTooltipHover";
import LoadingComponent from "./components/LoadingComponent";
import Logo from "./components/Logo";
import MonthSelection from "./components/MonthSelection";
import MoveToLocationButton from "./components/MoveToLocationButton";
import FishDataContext from "./context/FishDataContext";
import { FowardReferenceFishErrors } from "./types";

const apiKey = "AIzaSyAWmHnPhDPuMDHW3OKArL6N74S9fc3EINU"
const libraries: ["visualization", "places"] = ['visualization', "places"]

const App = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries
  })

  const fishErrors = useRef<FowardReferenceFishErrors>(null)


  const [map, setMap] = useState<google.maps.Map | null>(null)
  const cachedFishMap = useMemo(() => <FishMap setMap={setMap} hasHeatmap={map !== null} />, [map])

  if (!isLoaded) {
    return <LoadingComponent title="Loading Google Scripts..." />
  }
  if (map === null) {
    return (
      <>
        {cachedFishMap}
        <LoadingComponent title="Loading Map..." />
      </>
    )
  }
  if (loadError) {
    return <div>Map can not be loaded. {loadError.name}: {loadError.message}</div>
  }

  return (
    <>
      <FishDataContext
        apiKey={apiKey} map={map}
        setErrors={e => fishErrors?.current?.setErrors?.(e)}
        setTotalRequests={r => fishErrors?.current?.setTotalRequests?.(r)}
      >
        {cachedFishMap}
        <Logo />
        <MonthSelection />
        <MoveToLocationButton />
        <FishSelectionInputField />
        <FishInfoModal />
        <FishTooltipHover />
      </FishDataContext>
      <FishErrorLogModal ref={fishErrors} />
    </>
  )
}



export default App;
