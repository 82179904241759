import { forwardRef, RefObject, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react"
import { useFishContext } from "../context/FishDataContext"
import { IconCross, IconLocate } from "../Icons"
import { FishData, FishLocationData, ForwardReferenceFishInput } from "../types"

const FishSelectionInputField = () => {
  const { currentFishData, map } = useFishContext()

  const goToLocation = () => {
    const bounds = getBounds(currentFishData)
    if (bounds) {
      map.fitBounds(bounds)
      map.panToBounds(bounds)
    }
  }

  const [autoCorrect, setAutoCorrect] = useState<string[]>([])
  const fishRef = useRef<ForwardReferenceFishInput>(null)
  const fishermanRef = useRef<ForwardReferenceFishInput>(null)

  const [selectedMode, setSelectedMode] = useState<"fish" | "fisherman">("fish")

  const updateAutoCorrect = (values: string[], src: typeof selectedMode) => {
    setAutoCorrect(values)
    setSelectedMode(src)
  }

  return (
    <div className="pointer-events-none absolute left-3 max-sm:right-3 sm:w-56 sm:bottom-0 max-sm:bottom-16 top-0 flex flex-col-reverse ">
      <div className="pointer-events-auto flex-shrink-0 mb-7 flex flex-row h-24 bg-white rounded-md items-center justify-center pl-4 pr-2">
        <div className="flex flex-col items-center justify-center flex-grow">
          <FishInputAutoSuggest ref={fishRef} setAutoCorrect={a => updateAutoCorrect(a, "fish")} />
          <FishermanInputAutoSuggest ref={fishermanRef} setAutoCorrect={a => updateAutoCorrect(a, "fisherman")} />
        </div>
        <div className="flex flex-row justify-center">
          <button className="ml-1 hover:text-blue-600" onClick={goToLocation} >
            <IconLocate />
          </button>
          {/* <button className="ml-1 hover:text-red-600" onClick={() => {
            fishRef.current?.setTyped?.('')
            fishermanRef.current?.setTyped?.('')
          }} >
            <IconCross />
          </button> */}
        </div>
      </div>
      <AutoSuggestArea
        autoCorrectValues={autoCorrect}
        setTyped={t => (selectedMode === "fish" ? fishRef : fishermanRef).current?.setTyped?.(t)}
      />
    </div>
  )
}

const FishermanInputAutoSuggest = forwardRef<ForwardReferenceFishInput, { setAutoCorrect: (strings: string[]) => void }>
  (({ setAutoCorrect }, ref) => {
    const { allFishData, searchedFish, setFilteredFishermen } = useFishContext()
    const [typed, setTyped] = useState('')

    const allFisherman = useMemo(() =>
      Array.from(new Set(
        allFishData
          .filter(f => searchedFish === null || searchedFish.includes(f))
          .flatMap(f => f.locationData.map(d => d.fisherName))
      )).sort(),
      [allFishData, searchedFish])

    const getFilteredData = useCallback((typedValue: string = typed) =>
      allFisherman
        .filter(f => f.toLowerCase().includes(typedValue.toLowerCase()))
        .sort(),
      [allFisherman, typed])

    const updateAutocorrect = (selected: boolean, filtered: string[] = getFilteredData(), text: string = typed) => {
      if (selected && (filtered.length !== 1 || filtered[0] !== text)) {
        setAutoCorrect(filtered)
      } else {
        setAutoCorrect([])
      }
    }

    const updateTyped = (typed: string, shouldUpdate = true) => {
      const filtered = getFilteredData(typed)
      if (shouldUpdate) {
        updateAutocorrect(true, filtered, typed)
      }
      setTyped(typed)
      if (typed.length === 0) {
        setFilteredFishermen(null)
      } else {
        setFilteredFishermen(filtered)
      }
    }

    useImperativeHandle(ref, () => ({
      setTyped: updateTyped,
      onSet: () => updateAutocorrect(true)
    }))

    const inputRef = useRef<HTMLInputElement>(null)

    return (
      <InputWithAutoSuggest
        inputRef={inputRef}
        setFocused={updateAutocorrect}
        typed={typed}
        setTyped={updateTyped}
        placeholder="Type Fisherman"
      />
    )
  })

const FishInputAutoSuggest = forwardRef<ForwardReferenceFishInput, { setAutoCorrect: (strings: string[]) => void }>
  (({ setAutoCorrect }, ref) => {
    const { allFishData, filteredFishermen, setSearchedFish } = useFishContext()
    const [typed, setTyped] = useState('')

    const getFilteredData = useCallback((typedValue: string = typed) =>
      allFishData
        .filter(f => f.locationData.some(d => filteredFishermen === null || filteredFishermen.includes(d.fisherName)))
        .filter(f => f.fishName.toLowerCase().includes(typedValue.toLowerCase()))
        .sort((a, b) => a.fishName.localeCompare(b.fishName)),
      [allFishData, filteredFishermen, typed])

    const updateAutocorrect = (selected: boolean, filtered: readonly FishData[] = getFilteredData(), text: string = typed) => {
      if (selected && (filtered.length !== 1 || filtered[0].fishName !== text)) {
        setAutoCorrect(filtered.map(f => f.fishName))
      } else {
        setAutoCorrect([])
      }
    }

    const updateTyped = (typed: string, shouldUpdate = true) => {
      const filtered = getFilteredData(typed)
      if (shouldUpdate) {
        updateAutocorrect(true, filtered, typed)
      }
      setTyped(typed)
      if (typed.length === 0) {
        setSearchedFish(null)
      } else {
        setSearchedFish(filtered)
      }
    }

    useImperativeHandle(ref, () => ({
      setTyped: updateTyped,
      onSet: () => updateAutocorrect(true)
    }))


    const inputRef = useRef<HTMLInputElement>(null)

    return (
      <InputWithAutoSuggest
        inputRef={inputRef}
        setFocused={updateAutocorrect}
        typed={typed}
        setTyped={updateTyped}
        placeholder="Type Fish"
      />
    )
  })

const InputWithAutoSuggest = ({ typed, setTyped, setFocused, placeholder, inputRef }: { typed: string, setTyped: (val: string, shouldUpdate: boolean) => void, setFocused: (val: boolean) => void, placeholder: string, inputRef: RefObject<HTMLInputElement> }) => {
  return (
    <div className="w-full flex flex-row">
      <input
        ref={inputRef}
        className="h-7 w-full bg-gray-200 border border-black"
        value={typed}
        onChange={e => setTyped(e.target.value, true)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholder={placeholder}
        type="text"
      />
      <button className="ml-1 hover:text-red-600" onClick={() => setTyped('', inputRef.current === document.activeElement)} >
        <IconCross />
      </button>
    </div>
  )
}

const AutoSuggestArea = ({ autoCorrectValues, setTyped }: {
  autoCorrectValues: string[],
  setTyped: (val: string) => void
}) => {

  return (
    <>
      {autoCorrectValues.length !== 0 &&
        <div
          className="pointer-events-auto my-4 max-sm:right-3 sm:w-56 bg-gray-300 rounded-md min-h-0 overflow-hidden"
        // style={{ maxHeight: 'calc(100%)' }}
        >
          <div className="h-full overflow-auto">
            {autoCorrectValues.map((v, i) =>
              <div
                key={i}
                className="cursor-pointer hover:bg-blue-300 px-4 py-2"
                onMouseDown={e => {
                  setTyped(v)
                  if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur()
                  }
                  e.preventDefault()
                }}>
                {v}
              </div>
            )}
          </div>
        </div>
      }
    </>
  )

}

const getBounds = (currentFishData: readonly FishLocationData[]) => {
  const locations = currentFishData.flatMap(f => f.points.flatMap(v => v.locations.flatMap(l => l.location ? [l.location] : [])))
  if (locations.length === 0) {
    return
  }

  let minLat = locations[0].lat()
  let minLng = locations[0].lng()
  let maxLat = minLat
  let maxLng = minLng

  locations.forEach((location, i) => {
    if (i === 0) return
    const lat = location.lat()
    const lng = location.lng()

    minLat = Math.min(minLat, lat)
    minLng = Math.min(minLng, lng)
    maxLat = Math.max(maxLat, lat)
    maxLng = Math.max(maxLng, lng)
  })


  return new google.maps.LatLngBounds(
    new google.maps.LatLng(minLat - 5, minLng - 5),
    new google.maps.LatLng(maxLat + 5, maxLng + 5)
  )

}

export default FishSelectionInputField