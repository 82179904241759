import { GoogleMap, HeatmapLayer } from "@react-google-maps/api";
import { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { useFishContext } from "../context/FishDataContext";
import { haversineDistance } from "../Hooks";
import { FishLocationData, HeatMapForwardReference } from "./../types";


const FishMap = ({ setMap, hasHeatmap }: { setMap: (map: google.maps.Map) => void, hasHeatmap: boolean }) => {
  const ref = useRef<HeatMapForwardReference>(null)
  return (
    <GoogleMap
      onLoad={map => setMap(map)}
      mapContainerStyle={{
        height: "100%",
        width: "100%"
      }}
      center={{ lat: 52, lng: 0 }}
      zoom={5}
      options={{
        mapTypeId: google.maps.MapTypeId.HYBRID,
        maxZoom: 10,
        backgroundColor: '#0F1933',
        disableDefaultUI: true,
        disableDoubleClickZoom: true
      }}
      onClick={e => {
        ref.current?.onClick(e.latLng, e.domEvent as MouseEvent)
      }}
      onMouseMove={e => {
        ref.current?.onMouseMove(e.latLng, e.domEvent as MouseEvent)
      }}
      onMouseOut={() => ref.current?.removeHover()}
    >
      {hasHeatmap && <Heatmap ref={ref} />}
    </GoogleMap >
  )
}

const redGradient = [102, 102, 147, 193, 238, 244, 249, 255, 255, 255, 255]
const greenGradient = [255, 255, 255, 255, 255, 227, 198, 170, 113, 57, 0]

const grads = redGradient.flatMap((red, i) => {
  const green = greenGradient[i]

  const result: string[] = []
  const add = (red: number, green: number) => result.push(`rgba(${Math.round(red)}, ${Math.round(green)}, 0, 1)`)

  if (i === 0) {
    add(red, green)
    return result
  }

  const previousRed = redGradient[i - 1]
  const previousGreen = greenGradient[i - 1]

  const deltas = 20
  for (let i = 1; i <= deltas; i++) {
    const amount = i / deltas

    add(
      previousRed + (red - previousRed) * amount,
      previousGreen + (green - previousGreen) * amount
    )
  }

  return result

})

const radius = 1
const options: google.maps.visualization.HeatmapLayerOptions = {
  radius,
  dissipating: false,
  gradient: ["rgba(102, 255, 0, 0)"].concat(...grads)
}

const Heatmap = forwardRef<HeatMapForwardReference>((_, ref) => {
  const { currentFishData, hoveredFish, setHoveredFish, setSelectedFish, setSelectedLocationData, map } = useFishContext()

  const getFishDataUnderMouse = (mouse: google.maps.LatLng | null) => {
    const projection = map.getProjection()
    const zoom = map.getZoom()
    if (mouse !== null && projection !== undefined && zoom !== undefined) {
      const mousePoint = projection.fromLatLngToPoint(mouse)
      if (mousePoint !== null) {
        const { x, y } = mousePoint
        const hoveredData = currentFishData.map(f => ({
          originalFish: f.originalFish,
          points: f.points.filter(f => f.locations.some(({ location }) => {
            if (!location) {
              return false
            }
            const fishPoint = projection.fromLatLngToPoint(location) ?? { x, y }
            const dist = Math.sqrt(Math.pow(x - fishPoint.x, 2) + Math.pow(y - fishPoint.y, 2)) * (options.dissipating ? Math.pow(2, zoom) : 1)
            return dist <= radius
          }))
        })).filter(d => d.points.length !== 0)
        return hoveredData
      }
    }
    return []
  }

  useImperativeHandle(ref, () => ({
    onClick: latlng => {
      const data = getFishDataUnderMouse(latlng)
      setSelectedFish(data.map(f => f.originalFish))
      setSelectedLocationData(data)
    },
    onMouseMove: latlng => {
      const hovered = getFishDataUnderMouse(latlng)
      const isTheSame = hoveredFish.length === hovered.length && hovered.every(entry => hoveredFish.some(fish => {
        if (entry.originalFish !== fish.originalFish) {
          return false
        }
        if (entry.points.length !== fish.points.length) {
          return false
        }
        //The points are immutable
        return true
      }))
      if (!isTheSame) {
        setHoveredFish(hovered)
      }
      document.body.classList.toggle("cursor-must-hover", hovered.length !== 0)
    },
    removeHover: () => {
      setHoveredFish([])
      document.body.classList.remove("cursor-must-hover")
    }
  }))


  return (
    <>
      <HeatmapLayer
        options={options}
        data={useCombinedFishLocations(currentFishData)}
      />
      <HeatmapLayer
        options={options}
        data={useCombinedFishLocations(hoveredFish)}
      />
    </>
  )
})

const useCombinedFishLocations = (data: readonly FishLocationData[]) => useMemo(() => {
  const locations = data.flatMap(f => f.points).flatMap(f => f.locations)

  const returnLocations: google.maps.visualization.WeightedLocation[] = []
  locations.forEach(location => {
    if (!location.location) {
      return
    }
    for (let key of returnLocations) {
      if (!key.location) {
        continue
      }
      const dist = haversineDistance(location.location, key.location)
      if (dist < 500) {
        key.weight += location.weight
        return
      }
    }
    returnLocations.push({ ...location })
  })

  return returnLocations

}, [data])




export default FishMap