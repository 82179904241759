import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { IconCross } from "../Icons"
import { FowardReferenceFishErrors } from "../types"

const FishErrorLogModal = forwardRef<FowardReferenceFishErrors>((_, ref) => {
  const [errors, setErrors] = useState<string[]>()
  const [totalRequests, setTotalRequests] = useState(-1)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "E" && e.ctrlKey && e.shiftKey) {
        setIsOpen(o => !o)
        e.preventDefault()
      }
    }
    document.addEventListener("keydown", listener)
    return () => document.removeEventListener("keydown", listener)
  }, [])

  useImperativeHandle(ref, () => ({ setErrors, setTotalRequests }))
  return (
    <div className="pointer-events-none absolute left-0 top-0 right-0 bottom-0 overflow-hidden">
      <div className={"pointer-events-none duration-150 transform absolute top-0 right-0 h-full sm:w-1/2 sm:pb-24 max-sm:w-full p-4 " + (isOpen ? "" : "translate-x-full")}>
        <div className="pointer-events-auto relative rounded-xl overflow-hidden bg-white border border-gray-400 w-full max-h-full flex flex-col">
          <button onClick={() => setIsOpen(false)} className="absolute top-2 right-5 text-black hover:text-gray-500"><IconCross className="w-4 h-4" /></button>
          <div className="flex-grow overflow-x-hidden overflow-y-auto">
            <div className="w-full border-b border-black">Total Geocoding API requests: {totalRequests}</div>
            {errors && errors.map((err, i) => <div key={i}>{err}</div>)}
            {errors && errors.length === 0 && <div className="h-24 w-full flex justify-center items-center">No Errors</div>}
          </div>
        </div>
      </div>
    </div>
  )
})

export default FishErrorLogModal