import { IconLoadingSpin } from "../Icons"

const LoadingComponent = ({ title, subtitle }: { title: string, subtitle?: string }) => {
  return (
    <div className=" absolute top-0 left-0 w-full h-full bg-gray-400 flex flex-col justify-center items-center">
      <IconLoadingSpin className="w-16 h-16 text-white" />
      <span>{title}</span>
      <span className="text-gray-500">{subtitle}</span>
    </div>
  )
}

export default LoadingComponent