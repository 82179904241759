import { useEffect, useRef } from "react"
import { useFishContext } from "../context/FishDataContext"
import { capitalizeFirstLetter } from "../Util"

export const FishTooltipHover = () => {
  const { hoveredFish } = useFishContext()
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current === null) {
      return
    }
    const current = ref.current
    const onMouseMoveCallback = (e: MouseEvent) => {
      current.style.left = `${e.clientX}px`
      current.style.top = `${e.clientY}px`
    }
    document.addEventListener('mousemove', onMouseMoveCallback)
    return () => document.removeEventListener('mousemove', onMouseMoveCallback)
  })
  if (hoveredFish.length === 0) {
    return <></>
  }
  return (
    <div ref={ref} className="absolute bg-gray-300 rounded border border-gray-600 shadow pointer-events-none transform translate-y-6 leading-6 px-0.5" >
      Found Here: {hoveredFish.map(f => capitalizeFirstLetter(f.originalFish.fishName)).join(', ')}
    </div>
  )
}