
export type HeatMapForwardReference = {
  onClick: (latLong: google.maps.LatLng | null, event: MouseEvent) => void
  onMouseMove: (latLong: google.maps.LatLng | null, event: MouseEvent) => void
  removeHover: () => void
}
export type FishContext = {
  readonly isFirstTime: boolean,
  allFishData: readonly FishData[]
  currentFishData: readonly FishLocationData[]
  map: google.maps.Map,
  selectedMonth: MonthOrAll;
  setSelectedMonth: (val: MonthOrAll) => void;
  hoveredFish: readonly FishLocationData[];
  setHoveredFish: (val: readonly FishLocationData[]) => void;
  selectedFish: readonly FishData[];
  setSelectedFish: (val: readonly FishData[]) => void;
  searchedFish: readonly FishData[] | null;
  setSearchedFish: (val: readonly FishData[] | null) => void;
  filteredFishermen: readonly string[] | null;
  setFilteredFishermen: (val: readonly string[] | null) => void;
  selectedLocationData: readonly FishLocationData[];
  setSelectedLocationData: (val: readonly FishLocationData[]) => void;
}

export const months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"] as const
export type Month = typeof months[number]
export const monthsAndAll = (["All"] as MonthOrAll[]).concat(months)
export type MonthOrAll = Month | "All"

export type InternalFishLocationData<Loc = google.maps.visualization.WeightedLocation> = {
  fisherName: string,
  databaseRow: number,
  locationStrings: string[],
  locations: readonly Loc[],
  months: readonly Month[],
  notes?: string,
}

//Unlike FishData, there can be many instances of this
export type FishLocationData = {
  originalFish: FishData;
  points: InternalFishLocationData[]
}

type InternalFishData<Loc = google.maps.visualization.WeightedLocation> = {
  fishName: string,
  locationData: InternalFishLocationData<Loc>[]
}
export type FishData = InternalFishData
export type UnFormattedFishData = InternalFishData<string>

export type StorageItem = {
  placeName: string,
  locations: { lat: number, lng: number, weight: number }[]
}

export type LoadState = {
  message: string,
  amount?: number,
  total?: number
}

export type ForwardReferenceLoadState = {
  setLoadState: (state: LoadState) => void
}

export type FowardReferenceFishErrors = {
  setErrors: (errors: string[]) => void
  setTotalRequests: (req: number) => void
}

export type ForwardReferenceFishInput = {
  setTyped: (value: string) => void
  onSet: () => void
}