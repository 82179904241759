import { useEffect, useRef, useState } from "react"
import { useFishContext } from "../context/FishDataContext"
import { IconCross } from "../Icons"
import { FishLocationData, Month, months } from "../types"

const FishInfoModal = () => {
  const { selectedLocationData, setSelectedLocationData, setSelectedFish } = useFishContext()
  const previousSelectedFishData = useRef(selectedLocationData)
  if (selectedLocationData.length !== 0) {
    previousSelectedFishData.current = selectedLocationData
  }
  const [isClosing, setIsClosing] = useState(false)
  const closingTimeoutRef = useRef<NodeJS.Timeout>()

  //The problem: When closing a modal it has no contents.
  //The solution: store a reference to the previous selected data, 
  //              that only changes with a non empty selection. Then
  //              use that for rendering.
  const closeModal = () => {
    setSelectedLocationData([])
    setSelectedFish([])
    setIsClosing(true)
    closingTimeoutRef.current = setTimeout(() => setIsClosing(false), 150)
  }

  useEffect(() => () => {
    if (closingTimeoutRef.current !== undefined) {
      clearTimeout(closingTimeoutRef.current)
      setIsClosing(false)
    }
  }, [])

  const isShown = selectedLocationData.length !== 0

  return (
    <div className="pointer-events-none absolute left-0 top-0 right-0 bottom-0 overflow-hidden">
      <div className={"pointer-events-none duration-150 transform absolute top-0 right-0 h-full sm:w-1/2 sm:pb-24 max-sm:w-full p-4 " + (isShown && !isClosing ? "" : "translate-x-full")}>
        <div className="pointer-events-auto relative rounded-xl overflow-hidden bg-white border border-gray-400 w-full max-h-full flex flex-col">
          <button onClick={closeModal} className="absolute top-2 right-5 text-black hover:text-gray-500"><IconCross className="w-4 h-4" /></button>
          <div className="flex-grow overflow-x-hidden overflow-y-auto">
            {previousSelectedFishData.current.map(data => <FishInfoEntry key={data.originalFish.fishName} data={data} />)}
          </div>
        </div>
      </div>
    </div>
  )
}

const FishInfoEntry = ({ data }: { data: FishLocationData }) => {

  return (
    <div className="w-full min-h-32 group bg-white hover:bg-gray-400 p-2">
      <span className="font-bold">{data.originalFish.fishName}</span>
      <div className="m-2">
        {data.points.map((d, i) => (
          <div key={i} className="pb-2">
            {d.locationStrings.map((s, i) =>
              <a key={i} className="text-blue-600 hover:text-red-900 border-b-[1.5px] border-blue-600 hover:border-red-900" target="_blank" rel="noreferrer" href={"https://www.google.com/maps/search/" + encodeURIComponent(s)}>{s}</a>
            )}
            <br />
            Months: {calculateMonthRange(d.months)} <br />
            Credit: {d.fisherName} <br />
            Database Row: <a key={i} className="text-blue-600 hover:text-red-900 border-b-[1.5px] border-blue-600 hover:border-red-900" target="_blank" rel="noreferrer" href={`https://docs.google.com/spreadsheets/d/1-l773ZpFtTF97IagX47GkV2WEwB6bIohccQyaJyUTLk/edit#gid=1353708960&range=A${d.databaseRow}:D${d.databaseRow}`}>{d.databaseRow}</a>

          </div>

        ))}
        {/* {fish.locationData.map(d => d.)} */}
      </div>
    </div>
  )
}

const calculateMonthRange = (array: readonly Month[]): string => {
  if (array.length === 12) {
    return "All Year"
  }
  //Map the months to the indicies
  const indicies = array.map(m => months.indexOf(m)).sort((a, b) => a - b)

  const chains: string[][] = []
  let chainIndex = 0
  let previousIndex = -1
  for (let i = 0; i < indicies.length; i++) {
    const index = indicies[i]
    //If there is a different element that's not one away, we need to start a new chain
    if (previousIndex !== -1 && previousIndex !== index - 1) {
      chainIndex++
    }

    const chain = chains[chainIndex] ?? []
    chain.push(months[index])
    chains[chainIndex] = chain

    previousIndex = index
  }
  //If the chain starts at January, and ends with December, join them
  if (chains.length >= 2 && chains[0][0] === months[0]) {
    const lastChain = chains[chains.length - 1]
    const lastElem = lastChain[lastChain.length - 1]
    //If ends with december
    if (lastElem === months[11]) {
      chains.splice(chains.length - 1, 1)
      chains[0].unshift(...lastChain)
    }
  }
  return chains.map(chain => chain.length === 1 ? chain[0] : `${chain[0]}-${chain[chain.length - 1]}`).join(", ")
}

export default FishInfoModal